<template>
  <v-col>
    <v-data-table
      class="py-1 px-2 pb-3 rounded-lg shadow-regular"
      hide-default-footer
      :headers="headers"
      :no-data-text="t('Msg_.noDataAvailable')"
      :items="previousAssignments === null ? [] : previousAssignments"
      :item-class="row_classes"
      sort-by="name"
    >
      <template #top>
        <v-toolbar flat class="section-title">
          {{ t('Title_.subTitle.previousAssignments') }}
        </v-toolbar>
      </template>
      <template #[`item.grantedAt`]="{ item }">
        {{ helpers.formatDate(item.grantedAt) }}
      </template>
      <template #[`item.revokedAt`]="{ item }">
        {{ item.revokedAt ? helpers.formatDate(item.revokedAt) : '-' }}
      </template>
      <template #[`item.actions`]="{ item }">
        <v-icon
          color="blueRegular"
          :disabled="item.revokedAt === '' || !item.uploadedDocument"
          @click="downloadUploadedContract(item.id)"
        >
          mdi-file-outline
        </v-icon>
      </template>
    </v-data-table>
  </v-col>
</template>

<script setup>
import languages from '../../locales/languages';
import { useFleetVehiclesStore } from '@/store/pinia/fleet-vehicles.store.js';
import { useI18n } from 'vue-i18n-bridge';
import helpers from '@/helpers/helpers';
import { computed } from 'vue';

const fleetVehiclesStore = useFleetVehiclesStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Methods ---
const row_classes = (item) => {
  return item.revokedAt ? 'grey--text' : 'black--text';
};

const downloadUploadedContract = (item) => {
  window.open(
    `${process.env.VUE_APP_LOGIN_API}/v2/api/Assignments/${item}/download-uploaded-contract`,
    '_blank'
  );
};

// --- Computed ---
const previousAssignments = computed(() => {
  return fleetVehiclesStore.vehicleDetails?.previousAssignments || null;
});

const headers = computed(() => {
  return [
    {
      text: t('Action_.columnsHeader.driver'),
      sortable: true,
      color: 'blue',
      value: 'driver.completeName',
    },
    {
      text: t('Action_.columnsHeader.office'),
      value: 'hub.name',
    },
    {
      text: t('Action_.columnsHeader.start'),
      value: 'grantedAt',
    },
    {
      text: t('Action_.columnsHeader.end'),
      value: 'revokedAt',
    },

    {
      text: t('Action_.columnsHeader.actions'),
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ];
});
</script>
