<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" :class="type === 'fleet' ? 'mt-2 d-flex' : 'd-flex'">
        <v-container class="pa-0">
          <v-row>
            <v-col
              :cols="employeeSignatureConfig[type].size"
              :order="employeeSignatureConfig[type].order"
              :class="columnLayout()"
            >
              <v-tooltip
                top
                :disabled="!employeeSignaturePending || !!employeeSignature"
                data-testid="digital-signature-generating-signature-tooltip"
              >
                <template #activator="{ on }">
                  <div v-on="on">
                    <ButtonWithIcon
                      :disabled="
                        !employeeSignature || !employeeSignaturePending
                      "
                      class="main-action-icon-btn"
                      icon="mdi-share-variant"
                      :text="t('Action_.buttons.employeeSignatureLink')"
                      width="100%"
                      @click="emit('display-signature-employee')"
                      data-testid="digital-signature-employee-signature-btn"
                    />
                  </div>
                </template>
                <p>{{ t('Action_.tooltip.signatureLinkBeingGenerated') }}</p>
              </v-tooltip>
            </v-col>
            <v-col
              :cols="kenbiSignatureConfig[type].size"
              :order="kenbiSignatureConfig[type].order"
              :class="columnLayout()"
            >
              <v-tooltip
                top
                :disabled="!kenbiSignaturePending || !!kenbiSignature"
              >
                <template #activator="{ on }">
                  <div v-on="on">
                    <ButtonWithIcon
                      :disabled="disableKenbiSignature"
                      class="main-action-icon-btn"
                      icon="mdi-share-variant"
                      :text="t('Action_.buttons.kenbiSignatureLink')"
                      width="100%"
                      @click="emit('display-signature-kenbi')"
                      data-testid="digital-signature-kenbi-signature-btn"
                    />
                  </div>
                </template>
                <p>{{ t('Action_.tooltip.signatureLinkBeingGenerated') }}</p>
              </v-tooltip>
            </v-col>
            <v-col
              :cols="downloadConfig[type].size"
              :order="downloadConfig[type].order"
              :class="columnLayout()"
            >
              <v-tooltip top :disabled="!downloadPending">
                <template #activator="{ on }">
                  <div v-on="on">
                    <ButtonWithIcon
                      :disabled="!!downloadPending"
                      :outlined="true"
                      class="default-btn"
                      icon="mdi-download"
                      width="100%"
                      :text="t('Action_.buttons.downloadBlankContract')"
                      @click="emit('download')"
                      data-testid="digital-signature-download-blank-contract-btn"
                    />
                  </div>
                </template>
                <p>{{ t('Action_.tooltip.contractBeingGenerated') }}</p>
              </v-tooltip>
            </v-col>
            <v-col
              :cols="uploadConfig[type].size"
              :order="uploadConfig[type].order"
              :class="columnLayout()"
            >
              <ButtonWithIcon
                :outlined="true"
                class="default-btn"
                icon="mdi-upload"
                width="100%"
                :text="t('Action_.buttons.uploadSignedContract')"
                @click="triggerUploadWindow()"
                data-testid="digital-signature-upload-signed-contract-btn"
              />

              <input
                data-testid="upload-contract-input"
                ref="uploader"
                v-show="false"
                type="file"
                accept=".pdf"
                id="upload"
                @change="emit('upload', $event.target.files[0])"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import ButtonWithIcon from '@/components/shared/customComponents/buttons/button-with-icon.vue';
import languages from '../locales/languages';
import { useI18n } from 'vue-i18n-bridge';
import { ref, computed, defineProps, defineEmits } from 'vue';

const uploader = ref();
const { t } = useI18n({ locale: 'en', messages: languages });
const emit = defineEmits([
  'upload',
  'download',
  'display-signature-employee',
  'display-signature-kenbi',
]);

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  kenbiSignature: {
    type: String,
    default: null,
  },
  employeeSignature: {
    type: String,
    default: null,
  },
  downloadPending: {
    type: Boolean,
    default: false,
  },
  kenbiSignaturePending: {
    type: Boolean,
    default: false,
  },
  employeeSignaturePending: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    required: true,
  },
});

// --- Local variables ---
const layoutConfig = (order, size) => {
  return { order, size };
};

const employeeSignatureConfig = {
  fleet: layoutConfig(3, 12),
  profile: layoutConfig(1, 6),
  manager: layoutConfig(1, 6),
};

const kenbiSignatureConfig = {
  fleet: layoutConfig(4, 12),
  profile: layoutConfig(3, 6),
  manager: layoutConfig(3, 6),
};
const uploadConfig = {
  fleet: layoutConfig(1, 12),
  profile: layoutConfig(2, 6),
  manager: layoutConfig(2, 6),
};
const downloadConfig = {
  fleet: layoutConfig(2, 12),
  profile: layoutConfig(4, 6),
  manager: layoutConfig(4, 6),
};

// --- Methods ---
const columnLayout = () => {
  return props.type === 'fleet' ? 'pb-0' : 'flex-wrap';
};

const triggerUploadWindow = () => {
  uploader.value.click();
};

// --- Computed ---
const disableKenbiSignature = computed(() => {
  return (
    !props.kenbiSignature ||
    !props.kenbiSignaturePending ||
    props.type === 'profile'
  );
});
</script>

<style>
.btn-small {
  font-size: 12px !important;
}
</style>
