var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-col',[_c('v-card',{staticClass:"rounded-lg fill-height py-1 pb-3 px-2 shadow-regular"},[_c('v-card-title',{staticClass:"section-title"},[_vm._v(" "+_vm._s(_setup.t('Title_.subTitle.assignmentDetail'))+" ")]),(_setup.isAssigned)?_c('v-card-text',{staticClass:"card-text d-flex flex-column justify-space-between"},[_c(_setup.RegularTextInput,{attrs:{"label":_setup.t('Msg_.date'),"value":_setup.contractDate,"disabled":true}}),(!_setup.vehicleDetails.actualAssignment.uploadedDocument)?_c('v-container',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',[_c(_setup.RegularTextInput,{attrs:{"label":_setup.t('Msg_.contract'),"value":_setup.t('Msg_.placeholder'),"disabled":true}}),_c(_setup.DigitalSignatureStatus,{attrs:{"employee-signature":_setup.vehicleDetails.actualAssignment.signedByEmployee,"management-signature":_setup.vehicleDetails.actualAssignment.signedByManagement}})],1)],1)],1):_c('v-container',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',[_c(_setup.FileInput,{attrs:{"label":_setup.t('Msg_.contract'),"placeholder":_setup.vehicleDetails.actualAssignment.uploadedDocument.displayName,"value":_setup.vehicleDetails.actualAssignment.uploadedDocument,"downloadable":true,"disabled":true},on:{"download-document":function($event){return _setup.downloadUploadedContract(
                  _setup.vehicleDetails.actualAssignment.id,
                  true
                )},"display-document":function($event){return _setup.downloadUploadedContract(
                  _setup.vehicleDetails.actualAssignment.id,
                  false
                )}}})],1)],1)],1),_c(_setup.RegularTextInput,{attrs:{"label":_setup.t('Msg_.responsible'),"value":_setup.vehicleDetails.actualAssignment.responsible.completeName,"disabled":true}}),(_setup.canUser(['Fleet.Unassign']))?_c('v-btn',{staticClass:"cancel-variant-btn",attrs:{"data-testid":"fleet-assignment-detail-end-assignment-btn","block":""},on:{"click":function($event){return _setup.displayConfirmationDialog()}}},[_vm._v(" "+_vm._s(_setup.t('Action_.buttons.endAssignment'))+" ")]):_vm._e()],1):_c('v-card-text',[_c('v-row',{staticClass:"no-assigned-driver"},[_c('v-col',{attrs:{"align-self":"center"}},[_c('p',{staticClass:"text-center section-detail mb-0"},[_vm._v(" "+_vm._s(_setup.tm('Msg_.text.vehiclePending')[0])+" ")]),_c('p',{staticClass:"text-center section-detail"},[_vm._v(" "+_vm._s(_setup.tm('Msg_.text.vehiclePending')[1])+" ")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }