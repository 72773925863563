<template>
  <v-col>
    <v-card
      class="rounded-lg py-1 px-2 shadow-regular"
      id="assignment-container"
      data-testid="assigned-driver"
    >
      <v-card-title>
        <div class="section-title">
          {{ t('Title_.subTitle.assignedDriver') }}
        </div>
      </v-card-title>
      <v-card-text>
        <div v-if="isAssigned" data-testid="fleet-assigned-isassigned">
          <v-card class="pt-2 px-3" outlined>
            <v-row no-gutters>
              <v-col cols="auto">
                <v-avatar>
                  <v-img class="rounded-circle" alt="car Icon" size="36">
                    <v-icon size="33">mdi-account</v-icon>
                  </v-img>
                </v-avatar>
              </v-col>
              <v-col cols="auto" class="ml-2">
                <v-row>
                  <v-col
                    class="pb-0 section-title"
                    data-testid="fleet-assigned-driver-completename"
                  >
                    <span
                      @click="redirectToAssignedDriver()"
                      class="assigned-driver-redirect"
                    >
                      {{ actualAssignment.driver.completeName }}
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    class="pt-0 section-detail"
                    data-testid="fleet-assigned-driver-hubname"
                  >
                    {{ actualAssignment.driver.hubName }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-4 section-detail">
              <v-col>
                <v-row>
                  <v-col
                    class="pt-2 pb-0 d-flex align-center"
                    data-testid="fleet-assigned-driver-qualification"
                  >
                    <v-icon class="mr-2" color="greyRegular" small>
                      mdi-medical-bag
                    </v-icon>
                    {{ jobPosition }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    class="pt-1 pb-0"
                    data-testid="fleet-assigned-driver-phonenumber"
                  >
                    <v-icon class="pr-1 pb-1" color="greyRegular" small>
                      mdi-phone mdi-rotate-270
                    </v-icon>
                    {{ actualAssignment.driver.phoneNumber }}
                  </v-col>
                </v-row>
                <v-row class="mb-1">
                  <v-col
                    class="pt-1 pb-2"
                    data-testid="fleet-assigned-driver-email"
                  >
                    <v-icon class="pr-1" color="greyRegular" small>
                      mdi-email-outline
                    </v-icon>
                    {{ actualAssignment.driver.email }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>

          <div v-if="isAssignmentIncomplete">
            <v-row>
              <DigitalSignatureOptions
                class="my-4"
                :type="'fleet'"
                :employee-signature="actualAssignment.signUrlEmployee || null"
                :kenbi-signature="actualAssignment.signUrlManagement"
                :download-pending="isContractDownloadPending"
                :kenbi-signature-pending="!actualAssignment.signedByManagement"
                :employee-signature-pending="!actualAssignment.signedByEmployee"
                @display-signature-kenbi="
                  displaySignatureLinkDialog(
                    actualAssignment.signUrlManagement || null
                  )
                "
                @display-signature-employee="
                  displaySignatureLinkDialog(
                    actualAssignment.signUrlEmployee || null
                  )
                "
                @download="downloadContract()"
                @upload="uploadContractPDF"
              />
            </v-row>
          </div>
        </div>
        <div v-else data-testid="fleet-assigned-isnotassigned">
          <v-row class="no-assigned-driver">
            <v-col align-self="center">
              <p
                class="text-center section-detail mb-0"
                data-testid="no-assigned-text"
              >
                {{ tm('Msg_.text.vehiclePending')[0] }}
              </p>
              <p
                class="text-center section-detail"
                data-testid="no-assigned-text"
              >
                {{ tm('Msg_.text.vehiclePending')[1] }}
              </p>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              v-if="canUser(['Fleet.Assign'])"
              data-testid="assign-this-vehicle-btn"
              class="main-action-btn"
              block
              :to="{
                name: 'FleetVehiclesAssignment',
              }"
            >
              {{ t('Action_.buttons.assignThisVehicle') }}
            </v-btn>
          </v-card-actions>
        </div>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script setup>
import DigitalSignatureOptions from '@/components/shared/digitalSignature/digital-signature-options';
import languages from '../../locales/languages';
import { usePopupDialogStore } from '@/store/pinia/popup-dialog.store.js';
import { useFleetVehiclesStore } from '@/store/pinia/fleet-vehicles.store.js';
import { usePermissions } from '@/helpers/composables/permissions.js';
import { useI18n } from 'vue-i18n-bridge';
import helpers from '@/helpers/helpers';
import { ref, computed, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router/composables';

const router = useRouter();
const { canUser } = usePermissions();
const popupDialogStore = usePopupDialogStore();
const fleetVehiclesStore = useFleetVehiclesStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const uploadedFiles = ref([]);
const checkCurrentContractId = ref(null);
const isContractDownloadPending = ref(false);
const intervalStatus = ref({
  downloadInterval: false,
  signatureInterval: false,
});

// --- Methods ---
const getSignatureStatus = async () => {
  const signatureTimeInterval = 5000;
  try {
    await retryRequest(
      checkIfSignaturesAreReady,
      'signatureInterval',
      signatureTimeInterval
    );
  } catch (error) {
    console.log(error);
  } finally {
    clearInterval(intervalStatus.value.signatureInterval);
    intervalStatus.value.signatureInterval = null;
  }
};

const downloadContract = async () => {
  isContractDownloadPending.value = true;
  checkCurrentContractId.value =
    fleetVehiclesStore.vehicleDetails?.actualAssignment.id;
  const downloadTimeInterval = 1000;

  // const toastParams = {
  //   type: 'success',
  //   messages: {
  //     success: t('Msg_.toast.downloadInProgress.success'),
  //   },
  // };
  // this.$root.message.setToastParams(toastParams);

  // this.$root.message.showToastNotification();
  try {
    const downloadIsReady = await retryRequest(
      checkIfDownloadIsReady,
      'downloadInterval',
      downloadTimeInterval
    );
    if (downloadIsReady) {
      window.open(
        `${process.env.VUE_APP_LOGIN_API}/v2/api/Assignments/${checkCurrentContractId.value}/download-contract?download=true`,
        '_blank'
      );
    } else {
      throw new Error('Download not ready after max attempts.');
    }
  } catch (error) {
    console.log(error);
  } finally {
    clearInterval(intervalStatus.value.downloadInterval);
    intervalStatus.value.downloadInterval = null;
    isContractDownloadPending.value = false;
  }
};

const checkIfDownloadIsReady = async () => {
  try {
    const response = await fleetVehiclesStore.downloadCurrentContract(
      checkCurrentContractId.value
    );
    const ready = response?.contract?.documentStatus?.Value === 20; // 20 === documentStatus = "completed"
    return ready;
  } catch (error) {
    console.log(error);
  }
};

const checkIfSignaturesAreReady = async () => {
  try {
    await fleetVehiclesStore.getVehicleActualAssignment(
      fleetVehiclesStore.vehicleDetails.id
    );
    return hasSignatureLinks.value || !isAssigned.value;
  } catch (error) {
    console.log(error);
  }
};

const retryRequest = async (request, intervalName, time) => {
  let maxAttempts = 10;
  let receivedExpectedResponse;
  return new Promise((resolve, reject) => {
    try {
      intervalStatus.value[intervalName] = setInterval(async () => {
        receivedExpectedResponse = await request();
        if (receivedExpectedResponse) {
          resolve(receivedExpectedResponse);
          return;
        }
        if (maxAttempts === 0) {
          reject('Retry request max attempts failed.');
        }
        maxAttempts--;
      }, time);
    } catch (e) {
      resolve(false);
    }
  });
};

const uploadContractPDF = async (event) => {
  const actualAssignmentId =
    fleetVehiclesStore.vehicleDetails?.actualAssignment.id;
  const vehicleId = fleetVehiclesStore.vehicleDetails?.id;
  uploadedFiles.value = event;

  const payload = {
    actualAssignmentId,
    uploadedFiles: uploadedFiles.value,
  };

  // const toastParams = {
  //   type: 'error',
  // };

  // this.$root.message.setToastParams(toastParams);

  try {
    await fleetVehiclesStore.uploadContract(payload);
    await fleetVehiclesStore.getVehicleDetails(vehicleId);
  } catch (error) {
    console.log(error);
  }
};

const displaySignatureLinkDialog = (link) => {
  const params = {
    type: 'regular',
    title: t('Action_.dialog.signatureLink.title'),
    message: null,
    reinforcement: null,
    link: link,
    actionBtnText: t('Action_.dialog.signatureLink.button'),
    actionBtn: refreshPage,
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

const refreshPage = () => {
  router.go();
};

const redirectToAssignedDriver = () => {
  const driverId = actualAssignment.value.driver?.id;
  if (driverId) {
    routeToProfileMasterInformation(driverId);
  }
};

const routeToProfileMasterInformation = (id) => {
  router.push({
    name: 'EmployeeProfileMasterInformation',
    params: { employeeId: id },
  });
};

// --- Computed ---
const actualAssignment = computed(() => {
  return fleetVehiclesStore.vehicleDetails?.actualAssignment;
});

const isAssigned = computed(() => {
  return !!actualAssignment.value?.id || false;
});

const isAssignmentIncomplete = computed(() => {
  return (
    (!!actualAssignment.value &&
      !actualAssignment.value.uploadedDocument &&
      !isFullySigned.value) ||
    false
  );
});

const isFullySigned = computed(() => {
  return (
    (!!actualAssignment.value &&
      !!actualAssignment.value.signedByEmployee &&
      !!actualAssignment.value.signedByManagement) ||
    false
  );
});

const hasSignatureLinks = computed(() => {
  return (
    !!actualAssignment.value?.signUrlEmployee &&
    !!actualAssignment.value?.signUrlManagement
  );
});

const jobPosition = computed(() => {
  let actionSelectQualificationType = tm('Action_.qualificationTypes');

  return helpers.getTranslationById(
    actionSelectQualificationType,
    actualAssignment.value.driver.qualification
  );
});

// --- Lifecycle hooks ---
getSignatureStatus();

onBeforeUnmount(() => {
  clearInterval(intervalStatus.value.downloadInterval);
  clearInterval(intervalStatus.value.signatureInterval);
  intervalStatus.value.downloadInterval = null;
  intervalStatus.value.signatureInterval = null;
  isContractDownloadPending.value = false;
});
</script>

<style scoped lang="scss">
.no-assigned-driver {
  height: 250px;
}

#assignment-container {
  height: 100%;
}
.assigned-driver-redirect {
  cursor: pointer;
  text-decoration: underline !important;
}
.assigned-driver-redirect:hover {
  color: $blue-regular !important;
}
</style>
