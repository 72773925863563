<template>
  <v-col>
    <v-card class="rounded-lg fill-height py-1 pb-3 px-2 shadow-regular">
      <v-card-title class="section-title">
        {{ t('Title_.subTitle.assignmentDetail') }}
      </v-card-title>
      <v-card-text
        v-if="isAssigned"
        class="card-text d-flex flex-column justify-space-between"
      >
        <RegularTextInput
          :label="t('Msg_.date')"
          :value="contractDate"
          :disabled="true"
        />

        <v-container
          class="pa-0"
          v-if="!vehicleDetails.actualAssignment.uploadedDocument"
        >
          <v-row>
            <v-col>
              <RegularTextInput
                :label="t('Msg_.contract')"
                :value="t('Msg_.placeholder')"
                :disabled="true"
              />
              <DigitalSignatureStatus
                :employee-signature="
                  vehicleDetails.actualAssignment.signedByEmployee
                "
                :management-signature="
                  vehicleDetails.actualAssignment.signedByManagement
                "
              />
            </v-col>
          </v-row>
        </v-container>
        <v-container class="pa-0" v-else>
          <v-row>
            <v-col>
              <FileInput
                :label="t('Msg_.contract')"
                :placeholder="
                  vehicleDetails.actualAssignment.uploadedDocument.displayName
                "
                :value="vehicleDetails.actualAssignment.uploadedDocument"
                :downloadable="true"
                :disabled="true"
                @download-document="
                  downloadUploadedContract(
                    vehicleDetails.actualAssignment.id,
                    true
                  )
                "
                @display-document="
                  downloadUploadedContract(
                    vehicleDetails.actualAssignment.id,
                    false
                  )
                "
              />
            </v-col>
          </v-row>
        </v-container>
        <RegularTextInput
          :label="t('Msg_.responsible')"
          :value="vehicleDetails.actualAssignment.responsible.completeName"
          :disabled="true"
        />

        <v-btn
          v-if="canUser(['Fleet.Unassign'])"
          class="cancel-variant-btn"
          data-testid="fleet-assignment-detail-end-assignment-btn"
          block
          @click="displayConfirmationDialog()"
        >
          {{ t('Action_.buttons.endAssignment') }}
        </v-btn>
      </v-card-text>
      <v-card-text v-else>
        <v-row class="no-assigned-driver">
          <v-col align-self="center">
            <p class="text-center section-detail mb-0">
              {{ tm('Msg_.text.vehiclePending')[0] }}
            </p>
            <p class="text-center section-detail">
              {{ tm('Msg_.text.vehiclePending')[1] }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script setup>
import DigitalSignatureStatus from '@/components/shared/digitalSignature/digital-signature-status';
import FileInput from '@/components/shared/customComponents/inputs/file-input.vue';
import languages from '../../locales/languages';
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import { useUserStore } from '@/store/pinia/user.store.js';
import { usePopupDialogStore } from '@/store/pinia/popup-dialog.store.js';
import { useFleetVehiclesStore } from '@/store/pinia/fleet-vehicles.store.js';
import { usePermissions } from '@/helpers/composables/permissions.js';
import { useI18n } from 'vue-i18n-bridge';
import helpers from '@/helpers/helpers';
import { computed } from 'vue';

const userStore = useUserStore();
const { canUser } = usePermissions();
const popupDialogStore = usePopupDialogStore();
const fleetVehiclesStore = useFleetVehiclesStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Methods ---
const downloadUploadedContract = (item, download) => {
  if (download) {
    window.open(
      `${process.env.VUE_APP_LOGIN_API}/v2/api/Assignments/${item}/download-uploaded-contract?download=true`
    );
  } else {
    window.open(
      `${process.env.VUE_APP_LOGIN_API}/v2/api/Assignments/${item}/download-uploaded-contract`,
      '_blank'
    );
  }
};

const endAssignment = async () => {
  const actualAssignmentId =
    fleetVehiclesStore.vehicleDetails.actualAssignment.id;
  const vehicleId = fleetVehiclesStore.vehicleDetails.id;
  const payload = {
    Id: actualAssignmentId,
    RevokedBy: userStore.userInfo.sub,
  };

  try {
    await fleetVehiclesStore.endAssignment(payload);
    await fleetVehiclesStore.getVehicleDetails(vehicleId);
  } catch (error) {
    console.log(error);
  }
};

const displayConfirmationDialog = () => {
  const params = {
    type: 'confirmation',
    title: t('Action_.dialog.wantToEndAssignment.title'),
    message: t('Action_.dialog.wantToEndAssignment.body'),
    reinforcement: t('Action_.dialog.wantToEndAssignment.reinforcement'),
    actionBtnText: t('Action_.buttons.endAssignment'),
    actionBtn: endAssignment,
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

// --- Computed ---
const vehicleDetails = computed(() => {
  return fleetVehiclesStore.vehicleDetails;
});

const isAssigned = computed(() => {
  return fleetVehiclesStore.vehicleDetails?.actualAssignment?.id || false;
});

const contractDate = computed(() => {
  return helpers.formatDate(
    fleetVehiclesStore.vehicleDetails.actualAssignment?.grantedAt
  );
});
</script>

<style scoped lang="scss">
.no-assigned-driver {
  height: 250px;
}

.card-text {
  height: 83%;
}
</style>
